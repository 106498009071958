import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const FourOhFour = () => {
    return (
        <Layout>
            <img src="https://media.giphy.com/media/3o7btVRbshbbaC8Ygg/giphy.gif" alt="Hello Computer?" />
            <p>Sorry, the page your looking for cannot be found. 😥</p>
            <Link to="/">Go Home</Link>
        </Layout>
    )
}

export default FourOhFour